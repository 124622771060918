<template>
  <div class="content" v-if="info">
    <div class="other-title">预警信息</div>
    <div class="safe-detail-list">
      <div class="safe-detail-item">
        <div class="safe-detail-label">预警时间</div>
        <div class="safe-detail-text">{{ info.alarm_time || "-" }}</div>
      </div>
      <div class="safe-detail-item">
        <div class="safe-detail-label">预警类型</div>
        <div class="safe-detail-text">{{ (info.typeItem && info.typeItem.label) || "-" }}</div>
      </div>
      <div class="safe-detail-item">
        <div class="safe-detail-label">预警级别</div>
        <div class="safe-detail-text">{{ (info.levelItem && info.levelItem.label) || "-" }}</div>
      </div>
      <div class="safe-detail-item">
        <div class="safe-detail-label">设备名称</div>
        <div class="safe-detail-text">{{ info.device_name || "-" }}</div>
      </div>
      <div class="safe-detail-item">
        <div class="safe-detail-label">设备编号</div>
        <div class="safe-detail-text">{{ info.device_num || "-" }}</div>
      </div>
      <div class="safe-detail-item">
        <div class="safe-detail-label">设备位置</div>
        <div class="safe-detail-text">{{ info.device_location || "-" }}</div>
      </div>
      <div class="safe-detail-item">
        <div class="safe-detail-label">报警描述</div>
        <div class="safe-detail-text">{{ info.alarm_describe || "-" }}</div>
      </div>
    </div>
    <div class="other-title">处理信息</div>
    <div class="safe-detail-list">
      <div class="safe-detail-item">
        <div class="safe-detail-label">处理状态</div>
        <div class="safe-detail-text">{{ (info.statusItem && info.statusItem.label) || "-" }}</div>
      </div>
      <div class="safe-detail-item">
        <div class="safe-detail-label">预警编号</div>
        <div class="safe-detail-text">{{ info.alarm_num || "-" }}</div>
      </div>
      <template v-if="info.process_flow">
        <div class="safe-detail-item">
          <div class="safe-detail-label">处理流程</div>
          <div class="safe-detail-text">
            <div class="flow-item flex-box" v-for="(item, index) in info.process_flow.historyList" :key="index">
              <open-data type="userName" :openid="item.userId" />:
              <div class="flow-desc">{{ item.desc }}</div>
              <div class="flow-time">{{ item.time }}</div>
            </div>
          </div>
        </div>
        <template v-if="info.status == 5">
          <div class="safe-detail-item">
            <div class="safe-detail-label">处理信息</div>
            <div class="safe-detail-text">{{ info.process_flow.info || "-" }}</div>
          </div>
          <div class="safe-detail-item">
            <div class="safe-detail-label">现场图片</div>
            <div class="safe-detail-text">
              <div class="file-list" v-if="info.process_flow.list">
                <img
                  class="file-item"
                  :src="f"
                  v-for="(f, i) in info.process_flow.list"
                  :key="i"
                  @click="previewImg(i)"
                />
              </div>
              <div v-else>无</div>
            </div>
          </div>
        </template>
        <div class="safe-detail-item" v-if="info.status == 2">
          <div class="safe-detail-label">取消理由</div>
          <div class="safe-detail-text">{{ info.process_flow.reason || "-" }}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { levelOptions, warningOptions, statusOptions } from "../../common/constant/safe";

export default {
  name: "SafeDetail",
  data() {
    return {
      info: null,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$axios({
        url: `/admin/security-work-order/${this.id}`,
        method: "GET",
        noTempleFilter: true,
      }).then((res) => {
        this.dealInfo(res);
        this.info = res;
      });
    },
    dealInfo(info) {
      const levelItem = levelOptions.find((item) => item.value == info.alarm_level);
      info.levelItem = levelItem;
      const typeItem = warningOptions.find((item) => item.value == info.type);
      info.typeItem = typeItem;
      const statusItem = statusOptions.find((item) => item.value == info.status);
      info.statusItem = statusItem;
      if (info.process_flow) {
        let process_flow = JSON.parse(info.process_flow);
        info.process_flow = process_flow;
      }
      // info.process_flow = {
      //   list: [
      //     "https://lingyin-1301841918.cos.ap-shanghai.myqcloud.com/1693307365949K36TKNGbn8rhQzsjEeTECJ8YDhNyePcN.jpg",
      //     "https://lingyin-1301841918.cos.ap-shanghai.myqcloud.com/1693307365949K36TKNGbn8rhQzsjEeTECJ8YDhNyePcN.jpg",
      //   ],
      //   reason: "拒绝理由",
      //   info: "处理信息",
      //   historyList: [
      //     {
      //       userId: "woLnbtCQAAV2hf0EHATWu87UYXdxFg9g",
      //       cropId: "",
      //       desc: "未完成",
      //       time: "2023-09-13 00:00:00",
      //     },
      //     {
      //       userId: "woLnbtCQAAV2hf0EHATWu87UYXdxFg9g",
      //       cropId: "",
      //       desc: "处理中",
      //       time: "2023-09-13 00:00:00",
      //     },
      //     {
      //       userId: "woLnbtCQAAV2hf0EHATWu87UYXdxFg9g",
      //       cropId: "",
      //       desc: "已完成",
      //       time: "2023-09-13 00:00:00",
      //     },
      //   ],
      // };
    },
    previewImg(i) {
      let list = this.info.process_flow.list;
      const current = list[i];
      const urls = list.map((v) => {
        return {
          url: v,
          title: "现场图片",
        };
      });
      this.$store.dispatch("showImgList", urls, current);
    },
  },
};
</script>

<style lang="less" scoped>
.safe-detail {
  position: relative;
}
.safe-detail-list {
  padding: 0 40px 20px;
}
.safe-detail-item {
  display: flex;
  background-color: @component-background;
  padding: 4px 0;
  line-height: 1.5;
  font-size: 16px;
  .safe-detail-item {
    margin-bottom: 8px;
    padding: 0;
  }
}
.safe-detail-label {
  min-width: 120px;
  color: @text-color-secondary;
}
.safe-detail-text {
  flex: 1;
  margin-left: 20px;
  color: @text-color;
  text-align: justify;
}
.other-title .safe-detail-text {
  font-weight: normal;
  font-size: 16px;
}

.flow-item {
  line-height: 1.4;
  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
}
.flow-desc {
  margin-left: 8px;
}
.flow-time {
  margin-left: 24px;
}
</style>
